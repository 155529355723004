html, body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none; }

body {
  background-color: #021859;
  margin: 0; }
  body.nav-opened {
    background-color: #ffffff;
    overflow: hidden;
    transition: .25s background-color ease-in-out .27s; }

body.nav-open {
  background-color: #ffffff; }

.container {
  max-width: 1200px;
  width: 100%; }
  .container.flex {
    display: flex;
    flex-direction: row; }

@media (min-width: 1233px) {
  .container, .wrapper {
    margin-left: auto;
    margin-right: auto; }
  .wrapper {
    width: 100%;
    max-width: 1200px; } }

@media (max-width: 1232px) {
  .container, .wrapper {
    padding-left: 24px;
    padding-right: 24px; } }

@media (min-width: 768px) {
  .wrapper {
    padding-top: 72px; } }

@media (max-width: 768px) {
  .wrapper {
    padding-top: 64px; } }

body {
  font-family: 'Space Mono', monospace; }

a, p, span, h1, h2, h3, h4, h5, h6 {
  color: #ffffff;
  margin: 16px 0; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700; }

a, p {
  font-size: 16px;
  line-height: 24px; }

a {
  text-decoration: underline; }
  a:hover {
    text-decoration: none; }

.italic {
  font-style: italic; }

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 56px;
    line-height: 70px; }
  h2, .h2 {
    font-size: 48px;
    line-height: 60px; }
  h3, .h3 {
    font-size: 40px;
    line-height: 50px; }
  h4, .h4 {
    font-size: 32px;
    line-height: 40px; }
  h5, .h5 {
    font-size: 24px;
    line-height: 30px; }
  h6, .h6 {
    font-size: 16px;
    line-height: 20px; } }

@media (max-width: 768px) {
  h1, .h1 {
    font-size: 48px;
    line-height: 60px; }
  h2, .h2 {
    font-size: 40px;
    line-height: 50px; }
  h3, .h3 {
    font-size: 32px;
    line-height: 40px; }
  h4, .h4 {
    font-size: 24px;
    line-height: 30px; }
  h5, .h5 {
    font-size: 16px;
    line-height: 20px; }
  h6, .h6 {
    font-size: 16px;
    line-height: 20px; } }

nav {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 4;
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }
  nav span, nav a {
    margin: 0;
    text-decoration: none;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
  nav .container .left, nav .container .right {
    display: flex;
    align-items: center;
    flex: 50%; }
  nav .container .right {
    flex-direction: row-reverse; }
  nav.open span, nav.open a {
    color: #021859; }

@media (min-width: 768px) {
  nav {
    height: 72px; } }

@media (max-width: 768px) {
  nav {
    height: 64px; } }

.header-background {
  position: fixed;
  width: 100%;
  background-color: #021859;
  z-index: 2; }

@media (min-width: 768px) {
  .header-background {
    height: 72px; } }

@media (max-width: 768px) {
  .header-background {
    height: 64px; } }

.hamburger {
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }
  .hamburger-line {
    height: 4px;
    background-color: #ffffff; }
    .hamburger-line.top {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
    .hamburger-line.bottom {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }

.hamburger.open {
  transform: translateY(-6px); }
  .hamburger.open .hamburger-line {
    background-color: #021859; }
    .hamburger.open .hamburger-line.top {
      -webkit-transform: translateY(12px) rotate(45deg);
      -moz-transform: translateY(12px) rotate(45deg);
      -o-transform: translateY(12px) rotate(45deg);
      transform: translateY(12px) rotate(45deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }
    .hamburger.open .hamburger-line.bottom {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out; }

@media (min-width: 768px) {
  .hamburger {
    height: 40px;
    width: 40px; }
    .hamburger-line.top {
      margin-top: 12px;
      margin-bottom: 8px; }
    .hamburger-line.bottom {
      margin-bottom: 12px; } }

@media (max-width: 768px) {
  .hamburger {
    height: 32px;
    width: 32px; }
    .hamburger-line.top {
      margin-top: 8px;
      margin-bottom: 8px; }
    .hamburger-line.bottom {
      margin-bottom: 8px; } }

.menu {
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }
  .menu a {
    color: #021859; }
  .menu .container {
    flex-direction: row-reverse; }

@media (min-width: 1233px) {
  .menu .container {
    width: 100%; } }

@media (max-width: 1232px) {
  .menu .container {
    width: auto; } }

@media (min-width: 768px) {
  .menu {
    padding-top: 72px; } }

@media (max-width: 768px) {
  .menu {
    padding-top: 64px; } }

html,
body, #app {
  min-height: 100%;
  position: relative; }

.wrapper {
  overflow: hidden;
  display: block;
  position: relative; }

@media (min-width: 768px) {
  .wrapper {
    min-height: calc(100vh - 48px - 32px - 32px - 48px - 72px);
    padding-bottom: 160px; }
  footer .container.flex {
    height: 48px;
    flex-direction: row; } }

@media (max-width: 768px) {
  .wrapper {
    min-height: calc(100vh - 192px - 32px - 32px - 32px - 64px);
    padding-bottom: 288px; }
  footer .container.flex {
    height: 192px;
    flex-direction: column; }
    footer .container.flex .right {
      align-items: flex-start; } }

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px; }
  footer .container .left, footer .container .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  footer .container .left {
    flex: 1; }
  footer .container .right {
    align-items: flex-end;
    flex: 2; }
  footer .container a, footer .container span {
    margin: 0; }
  footer .container .footer-svg {
    fill: #ffffff; }
    footer .container .footer-svg.first {
      margin-right: 8px; }

.resume-item {
  padding-bottom: 16px; }
  .resume-item h5, .resume-item p {
    margin: 4px 0; }
  .resume-item .item {
    margin-left: 16px; }
    .resume-item .item:before {
      content: "* "; }

.experience {
  display: flex;
  flex-direction: column; }
  .experience .resume-item {
    width: 100%; }

.skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

@media (min-width: 992px) {
  .skills .resume-item {
    flex: 0 20%; } }

@media (min-width: 768px) and (max-width: 992px) {
  .skills .resume-item {
    flex: 0 33.33%; } }

@media (min-width: 576px) and (max-width: 768px) {
  .skills .resume-item {
    flex: 0 50%; } }

@media (max-width: 576px) {
  .skills .resume-item {
    flex: 0 50%; } }
